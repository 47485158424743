<template>
    <div class="result-wrap">
        <div class="label">
            <span>测评打星</span>
            <span class="color-block"></span>
        </div>
        <div class="content">
            <div class="stars">
                <div v-for="v in 5" :key="v">
                    <img v-if="v <= star" src="@/assets/imgs/star-0.png">
                    <img v-else-if="(v-star)<1 && (v-star)>0" src="@/assets/imgs/star-1.png">
                    <img v-else src="@/assets/imgs/star-2.png">
                </div>
            </div>
            <div class="comments">{{starDesc}}</div>
        </div>
        <div class="label">
            <span>测评解读</span>
            <span class="color-block"></span>
        </div>
        <div class="detail">
            <div class="title"><span class="dot"></span><span class="dot-name">基本信息</span></div>
            <img class="bmi-img" src="@/assets/imgs/bmi.png" alt="">
            <div class="formula">
                <div class="sign"><span>BMI=体重（kg）/身高（m）^2&nbsp;&nbsp;标准体重=身高（cm）-105=（kg）</span></div>
            </div>
            <div class="result" v-if="report.basic">
                <h4>解读结果：<br>{{report.basic[0]}}</h4>
                <span>{{report.basic[1]}}</span>
            </div>
            <div class="items" v-for="v in resultArr" :key="v.name">
                <div class="title"><span class="dot"></span><span class="dot-name">{{v.name}}</span></div>
                <div class="item-li" v-for="(item,index) in v.desc" :key="item">
                    <h4 v-if="v.desc.length>1">0{{index+1}}</h4>
                    <span>{{item}}</span>
                </div>
            </div>
        </div>
        <div class="btn" @click="test">重新测评</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            num1: "<18.5",
            num2: ">28.0",
            star: 0,
            tid: "",
            report: {},
            starDesc: "",
            resultArr: []
        }
    },
    mounted() {
        this.tid = localStorage.getItem('tid')
        this._getData()
    },
    methods: {
        _getData() {
            this.$api.result(this.tid).then(res => {
                this.star = res.data.star.num
                this.starDesc = res.data.star.desc
                this.report = res.data.report
                console.log(res.data.report)
                let arr = []
                for (let k in res.data.report) {
                    let str = ""
                    switch (k) {
                        case 'diet':
                            str = '饮食维度'
                            break;
                        case 'life':
                            str = '生活作息'
                            break;
                        case 'lose':
                            str = '减脂维度'
                            break;
                        case 'sport':
                            str = '运动维度'
                            break;
                    }
                    if (k !== 'basic') {
                        arr.push({
                            name: str,
                            desc: res.data.report[k]
                        })
                    }
                }
                this.resultArr = arr

            }).catch()
        },
        test() {
            this.$api.assessmentInfo('retry', this.tid).then(res => {
                if (+res.data.result.finish === 1) {
                    this.$router.push("/resultDefault")
                } else {
                    sessionStorage.setItem('type', 'retry')
                    this.$router.push("/")
                }
            }).catch()
        },
    }
}
</script>

<style scoped lang="less">
.result-wrap {
    padding: 20px 10px;
    color: #333;
    background: #EDEDED;
    .label {
        position: relative;
        margin-bottom: 13px;
        min-height: 15px;
        span {
            position: absolute;
            z-index: 1;
            font-size: 15px;
            font-weight: bold;
            line-height: 13.5px;
            left: 7px;
        }
        .color-block {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            width: 67px;
            height: 7.5px;
            left: 4.5px;
            background: rgba(153, 241, 178, .7);
            z-index: 0;
        }
    }
    .content {
        width: 355px;
        margin-bottom: 20px;
        .stars {
            height: 58px;
            background: #fff;
            border-radius: 10px 10px 0 0 ;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 31px;
                height: 31px;
                margin-right: 15.5px;
            }
        }
        .comments {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 10px 10px;
            min-height: 62px;
            padding: 0 19px;
            background: #DFFFE8;
            font-size: 12px;
            font-weight: bold;
            line-height: 18px;
            text-align: justify;
        }
    }
    .detail {
        width: 355px;
        padding: 15px 12.5px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        .title {
            display: flex;
            align-items: center;
            min-height: 15px;
            .dot {
                width: 6px;
                height: 6px;
                background: #B2F0C3;
                border-radius: 50%;
                margin-right: 7.5px;
            }
            .dot-name {
                font-size: 15px;
                font-weight: bold;
                line-height: 13.5px;
            }
        }
        .bmi-img {
            width: 330px;
            height: 33px;
            margin: 12px auto;
        }
        .formula {
            background: #F2F2F2;
            height: 24px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            .sign {
                display: inline-block;
                width: 330px;
                height: 24px;
                position: relative;
                overflow: hidden;
                text-align: center;//这个用来居中
                span {
                    position: absolute;
                    top: -50%;
                    left: -50%;
                    font-size: 20px;
                    width: 660px;
                    height: 48px;
                    line-height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: scale(0.5);
                    white-space: nowrap;//这个用来不让折行
                    box-sizing: border-box;
                }
            }

        }
        .result {
            font-size: 12px;
            line-height: 18px;
            text-align: justify;
            padding-bottom: 19px;
            h4 {
                font-weight: bold;
                margin: 15px 0 13px 0;
            }
        }
        .items {
            padding: 19px 0;
            border-top: 1px dotted #BFBFBF;
            .item-li {
                display: flex;
                justify-content: left;
                align-items: top;
                margin-top: 16px;
                padding-left: 6px;
                h4 {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;
                    margin-right: 11px;
                    margin-top: 2px;
                }
                span {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: justify;
                }
            }
        }
    }
    .btn {
        width: 283px;
        height: 42px;
        margin: 16px auto 26px auto;
        font-size: 18px;
        font-weight: bold;
        background: #99F1B2;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 8.5px 10px rgba(153, 241, 178, 0.27);
    }
}
</style>
