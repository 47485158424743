<template>
  <div class="{tid != 11?'body':''}">
    <div v-if="tid == 11">
      <van-empty class="custom-image">
        <template v-slot:image>
          <van-icon name="checked" size="100px" color="#07c160"/>
        </template>
        <template v-slot:description>
          <h4>感谢您的反馈！</h4>
        </template>
        <!-- <template v-slot:default>
          <van-button type="success" round plain @click="handlerBack" style="width: 140px">返回首页</van-button>
        </template> -->
      </van-empty>
    </div>

  <div  v-if="tid < 9">
    <h1>合理膳食建议</h1>
    <div class="container">
      <div class="content">
        <p><strong>饮食情况：</strong></p>
        <p>早餐不规律，增加低血糖的风险。</p>
        <p>吃宵夜频率过于频繁，不利于健康体重的控制。</p>
        <p>吃饭过快、吃饭过饱，不利于健康体重的控制。</p>
        <p>您的谷薯摄入过量，会影响其他类食物的摄入(如蔬菜、蛋白质等)，不利于营养均衡，建议您通过少吃半碗饭、减少薯类为主素菜酸辣士豆丝)等方式适当限制。</p>
        <p>您的深色蔬菜摄入不足，不同种类、颜色的蔬菜各有各营养特点，建议您丰富蔬菜种类，黄绿色等深色蔬菜占每日蔬菜摄入一半以上。</p>

        <p><strong>运动情况：</strong></p>
        <p>久坐时间过长，长时间久坐不利于血糖控制，建议每30分钟进行3分钟或更长时间的轻度活动例如步行、腿部伸展或手臂伸展。</p>
        <p>您当前存在运动受限情况，请谨慎运动。</p>

        <p><strong>烟酒情况：</strong></p>
        <p>请戒烟和避免使用烟草制品。吸烟会加剧胰岛素抵抗，可能会引发肺部疾病、心脏病和其他糖尿病并发症。</p>
        <p>请戒酒和减少使用酒精饮料。过量饮酒会降低血糖水平，可能会直接引发低血糖。另外，过量饮酒也会导致高血压、心力衰竭、中风等疾病。</p>

        <p><strong>生活作息和压力管理：</strong></p>
        <p>压力可能会导致血压和心率的上升，同时影响血糖平稳，建议进行适当的压力释放或管理。如果长时间处于压力较大的状态，难以缓解，建议咨询心理学专业人士。</p>
        <p>睡眠减少是血糖升高的危险因素，即使是一晚的低质量睡眠，也可能会加强胰岛素抵抗。建议找出影响睡眠的因素，可以尝试调暗室内灯光、利用香薰、音乐等帮助入睡。如果长期睡眠质量较差，建议就医。</p>

        <p><strong>如果您需要更具体和个性化的建议，欢迎扫码咨询山屿海医生团队及健康管理师团队。获取您的个性化定制健康管理方案</strong></p>
      </div>
      <div class="image-container">
        <img src="https://xajk.oss-cn-hangzhou.aliyuncs.com/syh001.jpg" alt="图片" />
      </div>
    </div>
  </div>

  <div  v-if="tid == 9">
    <h1>体质辨证（基础版）</h1>
    <div class="container">
      <div class="">
        <p><strong>欢迎入住幸福城国际康养基地</strong></p>
        <p class="p1">为了更好提供健康服务，需要对您做一个初步身体辩证，根据您的身体辩证结果为您准备适合您的茶包和泡浴包，请将结果给前台登记，并领取对应颜色的手环，愿您能有一个更好的入住体验！</p>
    
      </div>
    </div>
    <p><strong>一、适合您的手环颜色：</strong></p>
    <div class="container">
      <div class="info">
          <p class="constitution" v-if="showColor == 'yellow'">
            <span class="color-sample yellow"></span> 泻热祛湿  (黄色)
        </p>
        <p class="constitution" v-if="showColor == 'blue'">
            <span class="color-sample blue"></span> 疏肝解郁 (蓝色) 
        </p>
        <p class="constitution" v-if="showColor == 'red'">
            <span class="color-sample red"></span> 补气养血 (红色) 
        </p>
      </div>
    </div>
    <br/>
    <p><strong>二、适合您的天质茶：</strong><strong style="font-size:smaller;color:chocolate">(目前仅安吉中医养生馆提供)</strong></p>
    <div class="container">
      <div class="info">
        <p class="constitution">
          <span class="color-sample"></span>{{dateInfo}} 
      </p>
      </div>
    </div>
    <br/>
    <div v-if="showColor == 'yellow' ">
      <p><strong>三、适合您的养生汤：</strong><strong style="font-size:smaller;color:chocolate">(目前仅杭州幸福里提供尝鲜体验)</strong></p>
      <img class="img1" :src="imgArr[0]"><img>
      <p class="p1">夏季脾易受湿邪困扰，身体容易水肿，此药膳搭配的白扁豆、薏米、赤小豆是清热祛湿的利器，此药膳适合体内湿气重、口中黏腻，四肢沉重，身体困倦等人群服用。</p>
      <img class="img1" :src="imgArr[1]"><img>
      <p class="p1">清肺气，利咽喉。此药膳适合急慢性支气管炎、咳嗽咽痛、湿汗不止、吐白粘痰等人群服用。</p>
      <img class="img1" :src="imgArr[2]"><img>
      <p class="p1">火麻仁能润燥通肠，决明子用于热邪引起的便秘，含有大黄素成分，也有润肠通便的作用，此药膳适合由血虚津枯所导致的肠燥便秘人群服用。</p>
    </div>    
    <div v-if="showColor == 'blue' ">
      <p><strong>三、适合您的养生汤：</strong></p>
      <img class="img1" :src="imgArr[3]"><img>
      <p class="p1">《神农本草经》中，决明子味甘，苦，咸可以长期服用，帮助泄肝火，清肝明目，桑葚归肝肾经，可以很好的养肝血，补肾益气，并且桑葚含有白藜芦醇、芦丁、花青素等功能性成分，能有效预防心脑血管疾病，降血脂，延缓衰老等药理作用，这一款药膳非常适合面目红赤，口干舌燥，食欲不振，眩晕耳鸣，眼睛干涩等人群服用。</p>
      <img class="img1" :src="imgArr[4]"><img>
      <p class="p1">《别录》中记载，烦心不得眠可使，山屿海药膳汤搭配上好的百合，莲子，玉竹，能够帮助我们安定心神，安然入睡。百合养阴润肺，清心安神。一杯安神汤，愿它能给您带来一夜好眠。</p>
    </div>
    <div v-if="showColor == 'red' ">
      <p><strong>三、适合您的养生汤：</strong></p>
      <img class="img1" :src="imgArr[5]"><img>
      <p class="p1">夏季属火，五脏之中心与之对应，夏季养生一定要注意养心。《神农草本经》中将玉竹视为养脾胃的上品，玉竹还具有养阴润燥，生津止渴之效。百合清心安神，养阴润燥，茯苓具有利水渗湿，健脾宁心的功效，莲子清热降火，帮助睡眠，这一款药膳非常适合口干口苦，心烦燥热，失眠健忘的人群服用。</p>
      <img class="img1" :src="imgArr[6]"><img>
      <p class="p1">《中国药典》中记载，人参归脾、肺、心、肾、经。具有复脉固脱，补脾益肺等功效。黄精具有补气养阴健脾，润肺益肾等效果。覆盆子益肾固精，养肝明目等功效。此药膳非常适合由肾虚造成的腰膝酸软、须发白发、畏寒肢冷、等人群服用。</p>
      <img class="img1" :src="imgArr[7]"><img>
      <p class="p1">中医认为，白扁豆可以健脾和中、化湿消暑，可用于治疗脾胃虚弱，扁豆中的淀粉酶还能够一直在体内有效降低血糖的作用，薏米具有健脾祛湿、消热除湿的作用，枸杞红枣补肝益肾，美容养颜。此药膳适合体内湿气重、面色暗黑无光泽手足冰冷的人群长期服用。</p>
      <img class="img1" :src="imgArr[8]"><img>
      <p class="p1">人参具有生津、安神增智之功效，黄精主要是补气养阴、健脾益肾等功效，益智仁能暖肾固精，此药膳长期服用可提高免疫力，适用于血压低、头晕目眩四肢无力、精神萎靡等人群服用。</p>
    </div>
    <br/>
    <div >
      <p><strong>泡浴包介绍：</strong></p>
      <p class="p1">《黄帝内经》中说过，“其有邪者，渍形以为汗”，外邪进入身体后，可以在泡脚或泡澡中加入适合体质的中草药，经过热水熏蒸，药物就会发挥作用，刺激到全身的穴位和经络，给身体带来相应好处。药性由皮肤开始渗透到肌肉、经络、骨膜、骨缝，由外到内；再由内到外逐步排出体内的湿气、寒气、风邪、毒邪，从而畅通人体的排毒通道、打通机体代谢排毒系统，恢复机体原有的自愈力、提高机体免疫力。</p>
      <p><strong>天质茶介绍：</strong></p>
      <p class="p1">
        天质茶，即根据您的先天体质所制定，是山屿海中医诊所特制的药茶包；
        人的先天体质根据出生的季节有不同的规律，好比不同的人生下来性格就不同。根据天人合一的理论，人出生的时候会受到不同季节的影响。不同的季节有不同的天气地气，所以人出生在不同的季节本质上就带有不同的身体特质。这种体质的特质是根源性的，天质茶根据不同季节搭配不同的药材配伍保养就能让人预防疾病，提升体质。
        </p>
    </div>
  </div>

  <div  v-if="tid == 10">
      <div class="containert10">
        <br>
        <h1>五行健康评估结果</h1>
        <div class="max-score">
          <!-- 最大得分率的项目: {{ tid10List.maxCategory }}, 得分率: {{ tid10List.maxScoreRate }},  -->
          五行类型: {{ tid10List.maxElement }}
        </div>
        <br><br>
        <div v-for="(data, category) in tid10List.scores" :key="category" class="card">
          <p><strong>{{ category }}相关选项: </strong></p>
          <!-- <p><strong>分数:</strong> {{ data.score }} - 五行归属: {{ data.element }}</p> -->
          <p>{{ data.answers }}</p>
        </div>
      </div>
      <div class="btn" @click="retryTest">重新测评</div>
  </div>
</div>
</template>

<script>
import {Icon, Empty, Button} from 'vant';

export default {
  name: "ResultDefault",
  data() {
    return {
      num1: "<18.5",
      num2: ">28.0",
      star: 0,
      tid: "",
      report: {},
      starDesc: "",
      resultArr: [],
      showColor:"",
      dateInfo:"",
      imgArr: [
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520kso5Jc.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520mqrjob.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520Fudb3f.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520XBxSPg.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520E95bTh.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520rRYiQ2.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520rt7rzC.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/202405200Qe7vK.png",
        "https://xajk.oss-cn-hangzhou.aliyuncs.com/uPic/20240520GGmXRp.png",
      ],
      tid10List:[],
      
    }
  },
  components: {
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Button.name]: Button
  },
  created() {
    this.tid = this.$route.query.tid || localStorage.getItem('tid')
    console.log("tid",this.tid);
    if(this.tid == 9){
      this.test()
      console.log("that.imgArr",this.imgArr[0])
    }else if(this.tid == 10){
      this.testt10()
    }else{
      // this.getSources()
      window.history.pushState('forward', null, "#");
      window.history.forward(1)
      window.addEventListener("popstate", function () {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateBack({
          delta: 1
        })
      })
    }

  },
  methods: {
    retryTest() {
        this.$api.assessmentInfo('retry', this.tid).then(res => {
            if (+res.data.result.finish === 1) {
                this.$router.push("/resultDefault")
            } else {
                sessionStorage.setItem('type', 'retry')
                this.$router.push("/")
            }
        }).catch()
    },
    getSources(){
      this.$api.getSources().then(res => {
        console.log("res",res);
        // that.imgArr = res.data.data.imgs;
        // console.log("that.imgArr",that.imgArr);
      }).catch()
    },
    handlerBack() {
      // eslint-disable-next-line no-undef
      wx.miniProgram.switchTab({url: '/pages/newindex/newindex'})
    },

    testt10() {
      this.$api.assessmentInfo('try', this.tid).then(res => {
        if (+res.data.result.finish === 1) {
          this.tid10List = res.data.result.tid10List;
          console.log("testt10.res.data",this.tid10List);
          this.$router.push("/resultDefault")
        } else {
          console.log("res.data.result.finish");
          sessionStorage.setItem('type', 'retry')
          this.$router.push("/")
        }
      }).catch()
    },
    test() {
      this.$api.assessmentInfo('try', this.tid).then(res => {
        if (+res.data.result.finish === 1) {
          const data = res.data.result.dimList.answer;
          // 初始化分组统计对象
          const groups = {
              "A100": { countA: 0, countB: 0 },
              "A200": { countA: 0, countB: 0 },
              "A300": { countA: 0, countB: 0 }
          };
          console.log("data",data);

          if(data['D1001'] && data['D1001'].answer){
            if(data['D1001'].answer == 1 || data['D1001'].answer == 2 || data['D1001'].answer == 3) this.dateInfo = "春茶"
            if(data['D1001'].answer == 4 || data['D1001'].answer == 5 || data['D1001'].answer == 6) this.dateInfo = "夏茶"
            if(data['D1001'].answer == 7 || data['D1001'].answer == 8 || data['D1001'].answer == 9) this.dateInfo = "秋茶"
            if(data['D1001'].answer == 10 || data['D1001'].answer == 11 || data['D1001'].answer == 12) this.dateInfo = "冬茶"
            if(data['D1001'].answer > 12 ) this.dateInfo = "请咨询前台客服人员！"
          }
          // 遍历 JSON 数据
          for (const key in data) {
              if (key.startsWith('A100')) {
                  if (data[key].weight === 'A') {
                      groups["A100"].countA++;
                  } else if (data[key].weight === 'B') {
                      groups["A100"].countB++;
                  }
              } else if (key.startsWith('A200')) {
                  if (data[key].weight === 'A') {
                      groups["A200"].countA++;
                  } else if (data[key].weight === 'B') {
                      groups["A200"].countB++;
                  }
              } else if (key.startsWith('A300')) {
                  if (data[key].weight === 'A') {
                      groups["A300"].countA++;
                  } else if (data[key].weight === 'B') {
                      groups["A300"].countB++;
                  }
              }
          }
          // 输出结果
          console.log('A100 开头的条目中选择了 A 的个数:', groups["A100"].countA);
          console.log('A200 开头的条目中选择了 A 的个数:', groups["A200"].countA);
          console.log('A300 开头的条目中选择了 A 的个数:', groups["A300"].countA);

          // 比较三个数的大小并取最大值
          let maxCountA = Math.max(groups["A100"].countA, groups["A200"].countA, groups["A300"].countA);

          // 如果有相同的最大值，取前面一个（这里假设 A100 < A200 < A300）
          if (maxCountA === groups["A100"].countA) {
              maxCountA = groups["A100"].countA;
              this.showColor = "yellow";
          } else if (maxCountA === groups["A200"].countA) {
              maxCountA = groups["A200"].countA;
              this.showColor = "blue";
          } else if (maxCountA === groups["A300"].countA) {
              maxCountA = groups["A300"].countA;
              this.showColor = "red";
          }
          this.$router.push("/resultDefault")
        } else {
          console.log("res.data.result.finish");
          sessionStorage.setItem('type', 'retry')
          this.$router.push("/")
        }
      }).catch()
    },
  }
}
</script>

<style scoped lang="less">

.body {
  font-family: Arial, sans-serif;
  background-color: #f2fff2;
  padding: 20px;
  font-size: 14px;
}
h1 {
  color: #228b22;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
p {
  color: #000;
  margin-bottom: 10px;
  font-size: 16px;
}
p strong {
  font-size: 16px;
}
img {
  display: block;
  margin: 0 auto;
  max-width: 98%;
  height: auto;
  margin-top: 20px;
}
.p1{
  text-indent: 2em; /* 设置首行缩进2个字符 */
}
.img1 {
  display: block;
  max-width: 98%;
  height: auto;
  margin-top: 0px;
}

.info {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  max-width: 80%;
  margin: 0 auto;
}
.constitution {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;

}
.color-sample {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  background-color: #d0dbdf;
  /*background-color: #eee; 图标块背景颜色 */
  padding: 5px; /* 可选：增加内边距，使图标块更显眼 */
}
.yellow { background-color: #ffd700; }
.blue { background-color: #007bff; color: #fff; }
.red { background-color: #dc3545; color: #fff; }

/* 添加响应式样式 */
@media screen and (min-width: 768px) {
  /* 在大屏幕上布局图片和内容 */
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    width: 50%;
    padding-right: 20px;
   
  }
  img {
    width: 40%;
  }
}


.containert10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
  width: 90%; /* Adjust based on your layout needs */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.card h3 {
  color: #333;
  margin-bottom: 10px;
}

.card p {
  margin: 5px 0;
}

.max-score {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  width: 90%;
  text-align: center;
  padding: 10px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.btn {
  width: 283px;
  height: 42px;
  margin: 16px auto 26px auto;
  font-size: 18px;
  font-weight: bold;
  background: #99F1B2;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8.5px 10px rgba(153, 241, 178, 0.27);
}

.custom-image {
  /deep/ .van-empty__image {
    width: 100px;
    height: 100px;
  }

  /deep/ .van-empty__description {
    font-size: 30px;
    color: #000;
    margin-top: 30px;
  }
}
</style>
